import cloneDeep from 'lodash/cloneDeep';
import { Layouts } from '@/components/ui/charts/plotly/PlotlyHelpers';

// gives access to unit system, plotly chart
import ChartMiddlewareVue from '@/components/ui/charts/middleware/PlotlyChartMiddleware.vue';
import { ChartTypeEnum } from '@/components/ui/charts/ChartHelpers';

export default {
  name: 'PitchMovementChart',

  extends: ChartMiddlewareVue,

  data() {
    return {
      adjustRanges: true,
      chartType: ChartTypeEnum.PITCH_MOVEMENT.type,
      plotlyLayoutExtended: cloneDeep(Layouts.pitchMovementChart),
    };
  },

  methods: {
    customMapping(converted) {
      this.plotlyData = converted.map(this.mapDefaultTraceSettings);

      let symbol = this.unitSymbolX(this.unitSystem.system);
      this.plotlyLayoutExtended.title = { text: this.$vuetify.lang.t('$vuetify.reports.pitchMovementChart') };
      this.plotlyLayoutExtended.xaxis.title = `${this.xAxisTitle}, ${symbol}`;
      this.plotlyLayoutExtended.yaxis.title = `${this.yAxisTitle}, ${symbol}`;

      this.plotlyLayoutExtended.colorway = this.getColorsFromSeries(this.plotlyData);
    },
  },
};
